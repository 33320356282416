import { useEffect } from "react";

export default function ArticleHeaderEffects() {
  useEffect(() => {
    const setScroll = () => {
      const imageHeader = document.getElementById("article-header-image");

      if (imageHeader) {
        const scroll = document.documentElement.scrollTop;
        const height = document.documentElement.clientHeight;

        const opacity = Math.max(0.8 - scroll / height, 0);

        imageHeader.style.opacity = `${opacity}`;
      }
    };

    self.window.addEventListener("scroll", setScroll);

    return () => {
      self.window.removeEventListener("scroll", setScroll);
    };
  }, []);

  return <div />;
}
